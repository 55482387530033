import StorageService from './StorageService'

export default class ApiRequest{

    static debug = false

    // static baseUrl = "http://172.16.0.12:3000/"
    static baseUrl = 'http://3.15.1.42:3001/'
    // static baseUrl = "http://localhost:3001/"

    // static baseUrl = "http://www.getnimnim.us:3000/"

    static request = async(url, method = 'POST', data, headers = {}) => {
        let baseUrl = process.env.REACT_APP_API_URL;
        let token = StorageService.get('token')

        if(ApiRequest.debug)
        {
            url += '?XDEBUG_SESSION_START=1'
        }

        headers = this.bindAuth(token, headers)

        return fetch(baseUrl+url, {
            method: method, // or 'PUT'
            credentials: 'include',
            //body might create problem if request method is GET and debug is true
            body:(method !== 'GET' && data && Object.keys(data).length)?JSON.stringify(data):null, // data can be `string` or {object}!
            headers:{
                ...headers,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(function(response) {
            if(response.status === 401)
            {

            }
            return response.json();
        })
            .catch(error => console.error('Error:', error));
    }

    static multipart = async(url, method = 'POST', formData, headers = {}) => {
        let baseUrl = process.env.REACT_APP_API_URL;
        let token = StorageService.get('token')

        if(ApiRequest.debug)
        {
            url += '?XDEBUG_SESSION_START=1'
        }

        headers = this.bindAuth(token, headers)


        return fetch(baseUrl+url, {
            method: method, // or 'PUT'
            credentials: 'include',
            //body might create problem if request method is GET and debug is true
            body: formData, // data can be `string` or {object}!
            headers:{
                ...headers,
                'Accept': 'application/json'
            }
        }).then(function(response) {
                        if(response.status === 401)
            {

            }
            return response.json();
        })
            .catch(error => console.error('Error:', error));
    }

    static bindAuth(token, headers = {})
    {
        if(token)
        {
            headers.Authorization = 'Bearer '+token
        }
        return headers
    }

    static get(url, data, headers = {}){
        return ApiRequest.request(url, 'GET', data, headers);
    }

    static post(url, data, headers = {}){
        return ApiRequest.request(url, 'POST', data, headers);
    }

    static put(url, data, headers = {}){
        return ApiRequest.request(url, 'PUT', data, headers);
    }

    static delete(url, data, headers = {}){
        return ApiRequest.request(url, 'DELETE', data, headers);
    }

    static setDebug(bool){
        ApiRequest.debug = bool;
    }
}
